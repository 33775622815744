html, body {
  margin: 0;
  padding: 0;
  font-size: min(5vw, 24px);
}

html, body, input, button {
  font-family: Bitter, serif;
}

html, body, #app, .page-bg {
  min-height: 100vh;
}

.page-full {
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
}

.italic {
  font-style: italic;
}

.page-bg {
  color: #fff;
}

.page-bg.orange {
  background-color: #ff8742;
}

.page-bg.green {
  background-color: #2cba6b;
}

.page-bg.blue {
  background-color: #2da1d6;
}

.page-bg.yellow {
  background-color: #ffb30e;
}

.page-bg.pink {
  background-color: #ff7bad;
}

#holding-page-inner {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#auth-page-inner {
  height: 100%;
  min-height: 100%;
  max-width: 700px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2em;
  display: flex;
}

.page-col {
  max-width: 700px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2em;
  display: flex;
}

h1 {
  -webkit-text-decoration: underline wavy;
  text-decoration: underline wavy;
}

p.hint {
  opacity: .8;
  font-size: .7rem;
}

input {
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  background-color: #0000;
  border: 0;
  border-bottom: 3px solid #fff;
  margin-bottom: 1em;
  font-size: 1rem;
  font-weight: bold;
}

input.centered {
  text-align: center;
}

input::placeholder {
  color: #ffffff80;
}

input:focus {
  outline: none;
}

button {
  text-transform: uppercase;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  padding: .5em;
  font-size: 1rem;
  font-weight: bold;
}

button.green {
  color: #2cba6b;
}

button.blue {
  color: #2da1d6;
}

button.orange {
  color: #ff8742;
}

button.pink {
  color: #ff7bad;
}

button:hover {
  cursor: pointer;
  border-radius: 10px;
}

button.link {
  color: inherit;
  text-transform: none;
  background-color: #0000;
  font-size: .8rem;
  -webkit-text-decoration: underline wavy;
  text-decoration: underline wavy;
}

button.faded {
  opacity: .9;
  font-weight: normal;
}

/*# sourceMappingURL=index.94e8d14a.css.map */
