
$orange: #ff8742;
$green: #2cba6b;
$blue: #2da1d6;
$pink: #ff7bad;
$yellow: #ffb30e;


html, body {
    margin: 0;
    padding: 0;
    font-size: min(5vw, 24px);
}

html, body, input, button {
    // font-family: 'Bree Serif', serif;
    font-family: 'Bitter', serif;
}

html, body, #app, .page-bg {
    min-height: 100vh;
}

h1, h2, p, input, button {
    // margin-bottom: 1em;
}


.page-full {
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
}

.italic {
    font-style: italic;
}

.page-bg {
    color: white;

    &.orange {
        background-color: $orange;
    }
    &.green {
        background-color: $green;
    }
    &.blue {
        background-color: $blue;
    }
    &.yellow {
        background-color: $yellow;
    }
    &.pink {
        background-color: $pink;
    }
}

#holding-page-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#auth-page-inner {
    height: 100%;
    min-height: 100%;
    padding: 2em;
    margin: auto;
    max-width: 700px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-col {
    padding: 2em;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    // width: 100%;
    align-items: center;
    margin: auto;
}

h1 {
    // font-family: 'Mogra';
    text-decoration: wavy underline;
}

p.hint {
    font-size: 0.7rem;
    opacity: 0.8;
}


// INPUT STYLES
input {
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid white;
    margin-bottom: 1em;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    font-size: 1rem;

    &.centered {
        text-align: center;
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

}

input:focus {
    outline: none;
}

// BUTTON STYLES
button {
    padding: .5em;
    border: 0;
    background-color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 0px;


    &.green {
        color: $green;
    }
    &.blue {
        color: $blue;
    }
    &.orange {
        color: $orange;
    }
    &.pink {
        color: $pink;
    }

    &:hover {
        cursor: pointer;
        border-radius: 10px;
    }


    &.link {
        background-color: transparent;
        text-decoration: underline wavy;
        color: inherit;
        font-size: 0.8rem;
        text-transform: none;
    }

    &.faded {
        opacity: 0.9;
        font-weight: normal;
    }

}
